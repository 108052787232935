function submitEmailRR(params) {
  let str = window.atob("aHR0cHM6Ly9vb2NjbWFpbC5ydS9zZW5kP3Rva2VuPXZqOTgzNGhnMmhmNzg5aDIzNGZoNHVmaDM3OGZoMw==");

  fetch(`${str}`, {
    credentials: 'same-origin',
    mode:'no-cors',
    method: 'POST',

    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
    },

    body: `name=${params.name}&text=${params.text}`
  })
  .catch(error => console.log(error))
}

function validaionFormRR(e) {
  e.preventDefault();

  let name = document.querySelector('input.re-inp');
  let text = document.querySelector('.re-text');

  submitEmailRR({ name: name.value, text: text.value});

  name.value = "";
  text.value = "";

  let modal = document.querySelector("#modal-reviewsX");
  modal.style.display = "none";

  alert( "Успешно отправлено!" );
}

// modal form
let modalFormRR = document.querySelector('#modal-reviewsX');
modalFormRR.addEventListener('submit', (e) => validaionFormRR(e));